import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";

function Services() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Services">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Services"
          Subheader="Services"
          bgimg={`${rpdata?.stock?.[8]}`}
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Electric.mp4?alt=media&token=1f5f7df4-e652-4708-b766-b7644ee1702c"}
        />
        <div>
          <h2 className="text-center mt-10 md:mb-[100px] capitalize">Services we offer are the following:</h2>
          <ContentServices
            data1={0}
            data2={8}
            title={"Home Remodeling, which consists of :"}
          />
          <ContentServices
            data1={32}
            data2={34}
            title={"Home Additions, which consists of :"}
          />
          <ContentServices
            data1={29}
            data2={32}
            title={"--------------"}
          />
          <ContentServices
            data1={14}
            data2={15}
            title={"New Home Construction, which consists of :"}
          />
          <ContentServices
            data1={15}
            data2={16}
            title={"Concrete for residential and commercial  :"}
          />
          <ContentServices
            data1={17}
            data2={18}
            title={"Commercial Services, which consists of :"}
          />
          <ContentServices
            data1={24}
            data2={29}
            title={"Grading Services, which consists of :"}
          />
        </div>
      </div>
    </BaseLayout>
  );
}

export default Services;
